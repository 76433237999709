import Utils from "@/globals/Utils";
import Values from "@/globals/Values";

export default {
  //Login View

  setUser_info(state, data) {
    state.user_info.id = data.user.id;
    state.user_info.full_name = data.user.nombre;
    if (data.user.cargo == null) {
      state.user_info.position = "";
    } else {
      state.user_info.position = data.user.cargo;
    }
    if (data.user.telefono == null) {
      state.user_info.phone = "";
    } else {
      state.user_info.phone = data.user.telefono;
    }
    state.user_info.email = data.user.consultorEmail;
    state.user_info.company_name = data.proyectos.nombreEmpresa;
    state.user_info.tokens.tokenDC = data.proyectos.prDC[0];
    state.user_info.tokens.tokenPE = data.proyectos.prSL[0];
    state.user_info.authorisation_token = data.authorisation.token;
    state.user_info.countDC = data.proyectos.countDC;
    state.user_info.countPE = data.proyectos.countPE;
    state.user_info.urlTest =
      data.proyectos.urlAPI + "/testcultura/" + state.user_info.tokens.tokenDC;

    let current_user = JSON.stringify(state.user_info);
    sessionStorage.setItem("current_user", current_user);
    sessionStorage.setItem(
      "authorisation_token",
      state.user_info.authorisation_token
    );
  },

  getUserFromSessionStorage(state) {
    let current_user = sessionStorage.getItem("current_user");
    state.user_info = JSON.parse(current_user);
  },

  setUser(state, user) {
    state.user_info = structuredClone(user);
    let current_user = JSON.stringify(state.user_info);
    sessionStorage.setItem("current_user", current_user);
  },

  //CredentialsForm
  //Show Dialog to validate email for recovering password

  setTrue_login_email_validation_dialog(state) {
    state.login_email_validation_dialog = true;
  },

  setFalse_login_email_validation_dialog(state) {
    state.login_email_validation_dialog = false;
  },

  //Registration Form
  //Show Dialog to validate email for registration

  setTrue_registration_email_validation_dialog(state) {
    state.registration_email_validation_dialog = true;
  },

  setFalse_registration_email_validation_dialog(state) {
    state.registration_email_validation_dialog = false;
  },

  //Registered user waiting to confirm pin
  setRegistered_user(state, user) {
    state.registered_user.email = user.consultorEmail;
    state.registered_user.full_name = user.nombre;
  },

  setAllowDownloadReport(state, value) {
    state.allowDownloadReport = value === true ? true : false;
  },

  //Dashboart Set Data

  setTest_dialog_true(state) {
    state.test_dialog = true;
  },

  setTest_dialog_false(state) {
    state.test_dialog = false;
  },

  //Org Chart Data
  setOrg_serie(state, data) {
    let t = sessionStorage.getItem("texts");
    let texts = JSON.parse(t);
    this.dashboard1 = texts[37];
    this.dashboard2 = texts[38];
    let serie1 = [
      {
        name: texts[55],
        data: [],
      },
    ];

    serie1[0].data = data;

    state.org_serie = structuredClone(serie1);
  },

  //Team Chart Data
  setTeam_serie(state, data) {
    let t = sessionStorage.getItem("texts");
    let texts = JSON.parse(t);

    let serie2 = [
      {
        name: texts[55],
        data: [],
      },
      {
        name: texts[56],
        data: [],
      },
    ];
    serie2[0].data = data[0];
    serie2[1].data = data[1];

    state.team_serie = structuredClone(serie2);
  },

  //Archetypes Titles
  setArchetypes_titles(state, data) {
    state.archetypes_titles = data;

    state.org_chart_options.xaxis.categories = data;

    state.org_team_chart_options.xaxis.categories = data;
  },

  //Archetypes Text
  setArchetypes_text(state, data) {
    state.archetypes_text = data;
  },

  setArchetype_predominance(state, data) {
    state.archetype_predominance = data.sort(function (a, b) {
      return b.value - a.value;
    });
  },

  //Received Answers
  setSummary(state, data) {
    state.summary = data === 0 ? "N/D" : data;
  },

  //Internal Link to copy
  setInternal_link(state, data) {
    state.internal_link = data;
  },

  //Speedometer Percent
  setSpeedo_percent(state, data) {
    state.speedo_percent = data === 0 ? "N/D" : data;
  },

  //Profile Evaluation View

  //Empty candidates
  empty_candidates(state) {
    state.candidates = [];
  },

  //Set Candidates Data Table
  setCandidates(state, data) {
    let cand_array = [];

    for (let i = 0; i < data.filas; i++) {
      const parameter = data.parameter[i] || ["N/D", "N/D"];
      const parameterId = parameter[0] === "N/D" ? "N/D" : parameter[0];
      const parameterName = parameter[1] === null ? "N/D" : parameter[1];

      let cand = {
        index: i,
        fechaRegistro:
          data.fechaRegistro[i] == null
            ? "N/D"
            : new Date(data.fechaRegistro[i]),
        email_user: data.email[i] == null ? "N/D" : data.email[i],
        name: data.nombreCompleto[i] == null ? "N/D" : data.nombreCompleto[i],
        mindset_fit:
          data.mindsetFIT[i] == null || data.mindsetFIT[i] === undefined
            ? 0
            : data.mindsetFIT[i],
        dominant_individual_arq:
          data.arqTXT[i] == null ? "N/D" : data.arqTXT[i],
        parameter_id: parameterId,
        parameter_name: parameterName,
        parameter: parameter,
        tags: data.tags[i] == null ? "N/D" : data.tags[i],
        token: data.linkToken[i] == null ? "N/D" : data.linkToken[i],
        selectedParametrization: parameterId,
      };
      cand_array.push(cand);
    }

    cand_array.sort((a, b) => new Date(b.fechaRegistro) - new Date(a.fechaRegistro));
    cand_array.forEach(cand => cand.fechaRegistro.toLocaleString('es-ES'))

    const cand_array_sorted_by_register_date = cand_array.map(cand => ({
      ...cand,
      fechaRegistro: cand.fechaRegistro.toLocaleString('es-ES'),
    }))
        
    state.candidates = structuredClone(cand_array_sorted_by_register_date);
    state.candidates_to_show = structuredClone(cand_array_sorted_by_register_date);
  },
  setParametizationTable(state, payload) {
    let param_array = payload.data.map((item) => ({
      param_fechaRegistro: new Date(item.created_at).toLocaleString("es-ES"),
      param_name: item.name || "No name",
      param_data: item.data || "No data",
      param_id: item.id || 0,
    }));

    state.param_candidates = param_array;
  },
  setTotalParametization(state, payload) {
    let totalParam_array = payload.data.map((item) => ({
      totalParam_fechaRegistro: new Date(item.created_at).toLocaleString(
        "es-ES"
      ),
      totalParam_name: item.name || "No name",
      totalParam_data: item.data,
      totalParam_id: item.id,
    }));
    state.totalParametization = totalParam_array;
  },
  updateCandidateParametrization(state, payload) {
    const { candidateToken, paramId } = payload;
    const candidate = state.candidates_to_show.find(
      (cand) => cand.token === candidateToken
    );
    if (candidate) {
      candidate.selectedParametrization = paramId;
      candidate.mindset_fit = paramId === null ? "N/D" : candidate.mindset_fit;
    }
  },

  updateCandidatesList(state, candidates) {
    state.candidates_to_show = candidates;
  },
  setEmptyCandidates(state) {
    state.candidates = [];
    state.candidates_to_show = [];
  },

  filter_candidates(state, selected_tags) {
    state.candidates_to_show = structuredClone(state.candidates);
    let i;
    let tags_to_delete = [];
    let without_tags = selected_tags.includes(-1);
    for (let c = 0; c < state.candidates_to_show.length; c++) {
      if (state.candidates_to_show[c].tags.length != 0) {
        i = 0;
        while (
          i < state.candidates_to_show[c].tags.length &&
          !selected_tags.includes(state.candidates_to_show[c].tags[i].tag_id)
        ) {
          ++i;
        }
        if (i == state.candidates_to_show[c].tags.length) {
          tags_to_delete.push(c);
        }
      } else {
        if (!without_tags) {
          tags_to_delete.push(c);
        }
      }
    }

    for (let j = 0; j < tags_to_delete.length; j++) {
      if (j == 0) {
        state.candidates_to_show.splice(tags_to_delete[j], 1);
      } else {
        state.candidates_to_show.splice(tags_to_delete[j] - j, 1);
      }
    }
  },

  show_all_candidates(state) {
    state.candidates_to_show = structuredClone(state.candidates);
  },

  setExternal_link(state, link) {
    state.external_link = link;
  },

  updateCandidateTags(state, cand_index, new_tags) {
    state.candidates[cand_index].tags = new_tags;
  },

  //To Change Candidate General View to Candidate Detailed View
  detailed_view_change_step(state) {
    state.detailed_view_step = 0;
  },

  profiles_evaluation_view_change_step(state, cand) {
    state.selected_candidate = cand;
    state.detailed_view_step = 1;
  },
  profiles_view_change_step(state) {
    state.detailed_view_step = 1;
  },

  parametrization_view_change_step(state) {
    state.detailed_view_step = 2;
  },
  parametrization_view_change_step_to_1(state) {
    state.detailed_view_step = 3;
  },
  parametrization_view_change_step_to_desktop(state) {
    state.detailed_view_step = 4;
  },

  //To Change in Candidate Detailed View from Option 1: Culture Profile to Option 2: Engagement
  detailed_view_option1_change_step(state) {
    state.detailed_view_options_step = 1;
  },

  detailed_view_option2_change_step(state) {
    state.detailed_view_options_step = 2;
  },
  detailed_view_option3_change_step(state) {
    state.detailed_view_options_step = 3;
  },
  detailed_view_option4_change_step(state) {
    state.detailed_view_options_step = 4;
  },
  detailed_view_option5_change_step(state) {
    state.detailed_view_options_step = 5;
  },
  detailed_view_option6_change_step(state) {
    state.detailed_view_options_step = 6;
  },

  //Culture Profile

  setCultureProfileData(state, data) {
    state.cultureProfileData.series[0].data = data.graf2_1_1;

    state.cultureProfileData.arch_titles = data.arqTITULO;
    state.cultureProfileData.arch_titles_PRED = data.arqTITULOpred;
    state.cultureProfileData.arch_texts = data.arqTEXTOpred;
    state.cultureProfileData.principles_titles = data.valoresTITULO;
    state.cultureProfileData.principles_texts = data.valoresTEXTO;
  },

  setCultureProfilePredominance(state, data) {
    state.cultureProfileData.predominance = data;
  },

  setEngagementData(state, data) {
    state.engagementData.series_1[0].data =
      data === null || data === undefined || data.graf3_1_1 === undefined
        ? [0, 0, 0, 0, 0]
        : data.graf3_1_1;
    state.engagementData.series_1[1].data =
      data === null || data === undefined || data.graf3_1_2 === undefined
        ? [0, 0, 0, 0, 0]
        : data.graf3_1_2;
    state.engagementData.series_2[0].data =
      data === null || data === undefined || data.graf3_2_1 === undefined
        ? [0, 0, 0, 0, 0]
        : data.graf3_2_1;
    state.engagementData.series_2[1].data =
      data === null || data === undefined || data.graf3_2_2 === undefined
        ? [0, 0, 0, 0, 0]
        : data.graf3_2_2;
    state.engagementData.arch_titles = data.arqTITULO;
    state.engagementData.per_org_fit =
      data === null || data === undefined || data.kpi3_1 === undefined
        ? 0
        : data.kpi3_1;
    state.engagementData.des_org_fit =
      data === null || data === undefined || data.kpi3_2 === undefined
        ? 0
        : data.kpi3_2;
  },

  setMindsetIdealFitData(state, data) {
    if (!data || data.mindsetFit === undefined) {
      state.mindsetIdealFitData = {
        series_1: [{ data: [] }, { data: [] }],
        series_2: [{ data: [] }, { data: [] }],
        mindsetFitPercent: "N/D",
        paramName: "N/D",
      };
    } else {
      state.mindsetIdealFitData.series_1[0].data = data.param[0]?.pApar ?? Values.list6AllZero;
      state.mindsetIdealFitData.series_1[0].name = "Mentalidad ideal";
      state.mindsetIdealFitData.series_1[1].data = data.perfil[0]?.pAind ?? Values.list6AllZero;
      state.mindsetIdealFitData.series_1[1].name = "Mentalidad persona";

      state.mindsetIdealFitData.series_2[0].data = data.param[0]?.mVpar ?? Values.list36AllZero; // 36 valores de la empresa deseada 
      state.mindsetIdealFitData.series_2[0].name = "Valores ideales";
      state.mindsetIdealFitData.series_2[1].data = data.perfil[0]?.VALind ?? Values.list36AllZero; // 36 valores de como es el candidato
      state.mindsetIdealFitData.series_2[1].name = "Valores de la persona";

      state.mindsetIdealFitData.mindsetFitPercent = data.mindsetFit;
      state.mindsetIdealFitData.paramName = data.paramNAME;
    }
  },
  /*   data.linkToken[i] == null ? "N/D" : data.linkToken[i] */
  setEthicalData(state, data) {
    state.ethicalData.integrityIndicator = data.IntegridadIndicador;
    state.ethicalData.candidateIntegritySeries[0].data =
      data.IntegridadPuntuacion16;

    state.ethicalData.integrityValues = data.IntegridadValores;
    state.ethicalData.integrityValBehaviour = data.IntegridadValCOMPORTAMIENTOS;
    state.ethicalData.integrityProb = data.IntegridadProb;
  },
  setFitAreasData(state, data) {
    state.fitAreasData.archMax1Area = data.ARQmax1area;
    state.fitAreasData.archMax2Area = data.ARQmax2area;
    state.fitAreasData.archMin1Area = data.ARQmin1area;
    state.fitAreasData.archMin2Area = data.ARQmin2area;
  },
  setPredValuesData(state, data) {
    state.predValuesData.valuesTitle = data.valoresTITULO;
    state.predValuesData.valuesTexts = data.valoresTEXTO;
    state.predValuesData.valuesCompetences = data.valoresCOMPETENCIAS;
  },
  setLeaderData(state, data) {
    state.leaderData.transformacionalRAW = data.transfo;
    state.leaderData.transaccionalRAW = data.transac;
    state.leaderData.transformacionalPoints = data.KPItransformacional;
    state.leaderData.transaccionalPoints = data.KPItransaccional;
    state.leaderData.cuadrante1d4 = data.Cuadrante;
    state.leaderData.cuadranteText = data.textoCuadrante;
    state.leaderData.projectName = data.proyecto;
    state.leaderData.CandTkn = data.tokenCandidato;
    state.leaderData.series_3[0].data = data.transfo.LiderPuntuacion;

    state.leaderData.series_4[0].data = data.transac.LiderPuntuacion;
  },

  //Add New Tag
  add_new_tag(state, newtag) {
    state.tags_list.push(newtag);
  },

  delete_tag(state, tag) {
    let t_index = state.tags_list.indexOf(tag);
    state.tags_list.splice(t_index, 1);
    let index;
    for (let c of state.candidates_to_show) {
      index = 0;
      while (index < c.tags.length && c.tags[index].tag_id != tag.id) {
        ++index;
      }
      if (index < c.tags.length) {
        c.tags.splice(index, 1);
      }
    }
    state.candidates = structuredClone(state.candidates_to_show);
  },

  //Table Options

  candidates_equal(state) {
    state.candidates = structuredClone(state.candidates_to_show);
  },

  delete_candidate_tag(state, list) {
    let c = structuredClone(list.at(0));
    let tag = structuredClone(list.at(1));

    let t_index = 0;
    while (
      t_index < state.candidates_to_show[c.index].tags.length &&
      state.candidates_to_show[c.index].tags[t_index].id != tag.id
    ) {
      ++t_index;
    }

    state.candidates_to_show.at(c.index).tags.splice(t_index, 1);

    state.candidates = structuredClone(state.candidates_to_show);
  },

  add_candidate_tag(state, list) {
    let index = structuredClone(list.at(0));
    let tag = structuredClone(list.at(1));
    let cand_tag_id = structuredClone(list.at(2));

    let new_tag = {
      id: cand_tag_id,
      tag_id: tag.id,
      texto: tag.texto,
    };

    state.candidates_to_show.at(index).tags.push(new_tag);

    state.candidates = structuredClone(state.candidates_to_show);
  },

  //Set Tags
  setTags_list(state, data) {
    state.tags_list = data;
    state.filter_tags_value = [];
    for (let i = 0; i < data.length; i++) {
      state.filter_tags_value.push(false);
    }
  },

  setTrue_filter_tag_value(state, index_tag) {
    state.filter_tags_value[index_tag] = true;
  },

  setFalse_filter_tag_value(state, index_tag) {
    state.filter_tags_value[index_tag] = false;
  },

  //Dashboar App Bar Buttons
  dashboard_step_to_1(state) {
    state.dashboard_step = 1;
  },
  dashboard_step_to_2(state) {
    state.dashboard_step = 2;
  },
  dashboard_step_to_3(state) {
    state.dashboard_step = 3;
  },
  dashboard_step_to_4(state) {
    state.dashboard_step = 4;
  },

  setIntegrationKey(state, key) {
    state.integrationKey = key;
  },
  //Toogle Buttons Candidate Detailed View
  change_toggle_to_cultural(state) {
    state.toggle = "cultural_profile_btn";
  },
  change_toggle_to_engagement(state) {
    state.toggle = "engagement_btn";
  },
  change_toggle_to_ethical(state) {
    state.toggle = "ethical_btn";
  },
  change_toggle_to_leadership(state) {
    state.toggle = "leadership_btn";
  },
  change_toggle_to_pred_values(state) {
    state.toggle = "pred_values_btn";
  },
  change_toggle_to_parameterizationFit(state) {
    state.toggle = "parameterizationFit_btn";
  },

  SET_LANGUAGE(state, lang) {
    state.language = lang;
  },

  setOrderedHeaders(state, headers) {
    state.orderedHeaders = headers;
  },
  setCurrentStep(state, step) {
    state.detailed_view_step = step;
  },
  resetOrderedHeaders(state) {
    state.orderedHeaders = [];
  },
  resetOrderedHeadersToDefault(state) {
    state.orderedHeaders = []; // Resetear a su estado inicial o vacío
  },
  setSelectedParametrization(state, parametrization) {
    state.selectedParametrization.id = parametrization.id;
    state.selectedParametrization.name = parametrization.name;
    state.selectedParametrization.data = parametrization.data;
  },
  updateSelectedParametrizationData(state, newData) {
    state.selectedParametrization.data = newData;
  },
  setCandidateMindsetFit(state, data) {
    state.candidateMindsetFit = data.data
  },
  setDefaultParametrization(state, data) {
    state.defaultParametrization = {
      id: data.paramId,
      projectToken: data.projectToken,
    };
  },
  getCandidateSurvey(state, data) {
    const cultureOrganizationBlock = data?.survey?.cultureOrganizationBlock
    const personalBlock = data?.survey?.personalBlock

    state.candidateSurveyResult = [
      { 
        name: "Valores Culturales",
        data: Utils.transformaDataList(cultureOrganizationBlock) ?? Array(36).fill(0), // 36 valores de la cultura de la empresa
      },
      { 
        name: "Valores de la persona",
        data: Utils.transformaDataList(personalBlock) ?? Array(36).fill(0), // 36 valores del encuestado
      },
    ];
  },
  getAverageInnerSurvey(state, data) {
    state.averageInnerSurvey = data
  },
  setTeamAndCompanyAdjustment(state, data) {
    let t = sessionStorage.getItem("texts");
    let labels = JSON.parse(t);

    let currentVSTeam = [
      {
        name: labels[55], // Cultura actual
        data: [],
      },
      {
        name: labels[56], // Cultura equipo
        data: [],
      },
    ];
    currentVSTeam[0].data = data.pAorg;
    currentVSTeam[1].data = data.pAind;
    state.teamAndCompanyAdjustment = {
      currentVSTeam,
      KPI: data.fitT
    }
  },
};
